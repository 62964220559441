import { Client, createClient } from '@osdk/client'
import { createPublicOauthClient } from '@osdk/oauth'

export default function createClientAndAuth() {
  const client_id: string = process.env.REACT_APP_OSDK_CLIENTID as string
  const url: string = process.env.REACT_APP_OSDK_URL as string
  const ontologyRid: string = process.env.REACT_APP_OSDK_ONTOLOGYRID as string
  const redirectUrl: string = process.env.REACT_APP_OSDK_REDIRECTURL as string
  const scopes: string[] = ['api:ontologies-read', 'api:ontologies-write']

  const auth = createPublicOauthClient(client_id, url, redirectUrl, true, undefined, window.location.toString(), scopes)
  const client: Client = createClient(url, ontologyRid, auth)

  return { client, auth }
}
