import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PATH } from './data/constants/paths'
import { AppInsightsProvider } from './app-insights'
import { OsdkProvider } from '@osdk/react'
import createClientAndAuth from './services/osdk-client'

const { client, auth } = createClientAndAuth()
export { auth }

function App() {
  const navigate = useNavigate()
  const [authenticating, setAuthenticating] = useState(true)
  const [error, setError] = useState<string | undefined>(undefined)

  //adding check

  useEffect(() => {
    auth
      .signIn()
      .then(() => {
        setAuthenticating(false)
        if (window.location.pathname === '/') {
          navigate(PATH.SITE_OVERVIEW)
        }
      })
      .catch((e: unknown) => {
        setError((e as Error).message ?? String(e))
        setAuthenticating(false)
      })
  }, [navigate])

  if (authenticating) {
    return <div>Authenticating...</div>
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return (
    <AppInsightsProvider>
      <OsdkProvider client={client}>
        <div className="App">{}</div>
      </OsdkProvider>
    </AppInsightsProvider>
  )
}

export default App
